// import { configureStore } from "@reduxjs/toolkit";
// import rootReducer from "./rootreducer"; 
// const store = configureStore({
//   reducer: rootReducer,
// });

// export default store;





import { createStore } from "redux";

const initialState = {
  rows: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ROW":
      return { ...state, rows: [...state.rows, action.payload] };
    case "UPDATE_ROW":
      return {
        ...state,
        rows: state.rows.map((row, index) =>
          index === action.payload.index ? action.payload.row : row
        ),
      };
    default:
      return state;
  }
};

const store = createStore(reducer);
export default store;

