import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import EntryData from "./Formdata";
// import EditableTable from "./Editable";
import DataTableComponent from "./Form";


ReactDOM.render(
  <Provider store={store}>
    {/* <App /> */}
    {/* <EditableTable/> */}
    <EntryData/>
    {/* <EmployeeTotal/> */}
  </Provider>,
  document.getElementById("root"));