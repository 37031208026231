import React, { useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import axios from "axios";
import "./App.css";
import html2canvas from "html2canvas";
import { TableFooter } from "@mui/material";
import Box from "@mui/material/Box";


const initialRows = [
  {
    id: 1,
    // date: new Date().toLocaleDateString(),
    atmId: "60507905",
    atmName: "Cherukuru",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 2,
    // date: new Date().toLocaleDateString(),
    atmId: "40316014",
    atmName: "Jandrapeta",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 3,
    // date: new Date().toLocaleDateString(),
    atmId: "40294305",
    atmName: "Polytechnic College",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 4,
    // date: new Date().toLocaleDateString(),
    atmId: "40316010",
    atmName: "Nehru Market",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 5,
    // date: new Date().toLocaleDateString(),
    atmId: "40316011",
    atmName: "MGC",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 6,
    // date: new Date().toLocaleDateString(),
    atmId: "40316012",
    atmName: "Darbar Road",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 7,
    // date: new Date().toLocaleDateString(),
    atmId: "40316013",
    atmName: "ITC",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 8,
    // date: new Date().toLocaleDateString(),
    atmId: "40516101",
    atmName: "Pandilla Palli",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 9,
    // date: new Date().toLocaleDateString(),
    atmId: "40416105",
    atmName: "Kothapeta",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 10,
    // date: new Date().toLocaleDateString(),
    atmId: "40416106",
    atmName: "Government",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 11,
    // date: new Date().toLocaleDateString(),
    atmId: "40416108",
    atmName: "Vodarevu",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 12,
    // date: new Date().toLocaleDateString(),
    atmId: "40594301",
    atmName: "Vedulapalli",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 13,
    // date: new Date().toLocaleDateString(),
    atmId: "40594304",
    atmName: "Suryalanka Beach",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 14,
    // date: new Date().toLocaleDateString(),
    atmId: "60416109",
    atmName: "Vodarevu 2.0",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 15,
    // date: new Date().toLocaleDateString(),
    atmId: "40316008",
    atmName: "HPCL Chirala",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 16,
    // date: new Date().toLocaleDateString(),
    atmId: "40407904",
    atmName: "Parchur",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 17,
    // date: new Date().toLocaleDateString(),
    atmId: "40514303",
    atmName: "Pusapadu Inkollu",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 18,
    // date: new Date().toLocaleDateString(),
    atmId: "40316104",
    atmName: "HPCL Vetapalem",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 19,
    // date: new Date().toLocaleDateString(),
    atmId: "40316009",
    atmName: "RTC Chirala",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 20,
    // date: new Date().toLocaleDateString(),
    atmId: "40599502",
    atmName: "Swarna",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 21,
    // date: new Date().toLocaleDateString(),
    atmId: "60507903",
    atmName: "Nuthalapadu Parchur",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 22,
    // date: new Date().toLocaleDateString(),
    atmId: "40316102",
    atmName: "vetapalem",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
];

const EntryData = () => {
  const [filters, setFilters] = useState({
    atmId: "",
    atmName: "",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
    employee: "",
  });
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [rows, setRows] = useState(initialRows);
  const [editRowId, setEditRowId] = useState(null);
  const [totals, setTotals] = useState({
    total100: 0,
    total200: 0,
    total500: 0,
  });
  const [secondRow, setSecondRow] = useState({
    total100: "",
    total200: "",
    total500: "",
  });

  const employeeNames = [...new Set(initialRows.map((emp) => emp.name))];
  const filteredData = selectedEmployee
    ? initialRows.filter((emp) => emp.name === selectedEmployee)
    : initialRows;
  const totalss = filteredData.reduce(
    (sum, emp) => sum + emp.cash100 + emp.cash200 + emp.cash500,
    filters.selectedEmployee
  );
  console.log("Totalsss", totalss);

  const filteredRows = rows.filter((row) => {
    return (
      (row.atmId?.toString().includes(filters.atmId) || filters.atmId === "") &&
      (row.atmName?.toString().includes(filters.atmName) ||
        filters.atmName === "") &&
      (row.cash100?.toString().includes(filters.cash100) ||
        filters.cash100 === "") &&
      (row.cash200?.toString().includes(filters.cash200) ||
        filters.cash200 === "") &&
      (row.cash500?.toString().includes(filters.cash500) ||
        filters.cash500 === "") &&
      (row.employee?.toString().includes(filters.employee) ||
        filters.employee === "")
    );
  });

  const handleFilterChange = (e, field) => {
    setFilters({ ...filters, [field]: e.target.value });
    setSelectedEmployee(e.target.value);
  };

  const handleInputChange = (event, id, fieldName) => {
    const newValue = parseInt(event.target.value, 10) || 0;
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        const updatedRow = { ...row, [fieldName]: newValue };
        updatedRow.total = ["cash100", "cash200", "cash500"].reduce(
          (sum, key) => sum + (parseInt(updatedRow[key], 10) || 0),
          0
        );
        return updatedRow;
      }
      return row;
    });

    setRows(updatedRows);

    const newTotals = updatedRows.reduce(
      (acc, row) => {
        acc.total100 += parseInt(row.cash100, 10) || 0;
        acc.total200 += parseInt(row.cash200, 10) || 0;
        acc.total500 += parseInt(row.cash500, 10) || 0;
        return acc;
      },
      { total100: 0, total200: 0, total500: 0 }
    );
    setTotals(newTotals);
  };

  const handleEdit = (id) => {
    setEditRowId(id);
  };

  const handleSave = () => {
    setEditRowId(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:3001/api/save-data",
        {
          atmData: rows,
          t100: totals.total100,
          t200: totals.total200,
          t500: totals.total500,
          total: totals.total100 + totals.total200 + totals.total500,
          tStamp: new Date().toLocaleDateString(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      console.error("An error occurred during form submission:", error);
    }
  };

  const handleChange1 = (e, field) => {
    setSecondRow({ ...secondRow, [field]: e.target.value });
  };

  const isMatching100 = parseInt(secondRow.total100) === totals.total100;
  const isMatching200 = parseInt(secondRow.total200) === totals.total200;
  const isMatching500 = parseInt(secondRow.total500) === totals.total500;
  const isMatchingTotal =
    parseInt(secondRow.total100 || "0") +
      parseInt(secondRow.total200 || "0") +
      parseInt(secondRow.total500 || "0") ===
    totals.total100 + totals.total200 + totals.total500;

  const getStyledSymbol = (isMatching) => (
    <span style={{ color: isMatching ? "green" : "red" }}>
      {isMatching ? "✔" : "✘"}
    </span>
  );

  const handleEmployeeChange = (e, id) => {
    const value = e.target.value;
    if (id) {
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { ...row, employee: value } : row
        )
      );
    } else {
      setSelectedEmployee(value);
    }
  };

  const calculateTotals = React.useCallback(() => {
    const filteredRows = selectedEmployee
      ? rows.filter((row) => row.employee === selectedEmployee)
      : rows;

    const cash100 = filteredRows.reduce(
      (acc, row) => acc + (row.cash100 || 0),
      0
    );
    const cash200 = filteredRows.reduce(
      (acc, row) => acc + (row.cash200 || 0),
      0
    );
    const cash500 = filteredRows.reduce(
      (acc, row) => acc + (row.cash500 || 0),
      0
    );
    const total100 = filteredRows.reduce(
      (acc, row) => acc + (row.total100 || 0),
      0
    );
    return {
      cash100,
      cash200,
      cash500,
      total: cash100 + cash200 + cash500,
      total100,
    };
  }, [rows, selectedEmployee]);

  const row = calculateTotals();

  // const row = React.useMemo(() => calculateTotals(), [calculateTotals]);
  console.log("Totals:", totals);
  console.log("Rows:", rows);
  console.log("Selected Employee:", selectedEmployee);

  let handleDownloadScreenshot = async () => {
    const elementToCapture = document.getElementById("capture-section");
    if (elementToCapture) {
      console.log("Element found:", elementToCapture);
      const canvas = await html2canvas(elementToCapture);
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = "screenshot.png";
      link.click();
    } else {
      console.error("Element not found!");
    }
  };
// ////////////employee individual table ///////////

// const [tables, setTables] = useState(employeeTables);
//   const handleInputChange2 = (employee, rowId, field, value) => {
//     setTables((prev) => ({
//       ...prev,
//       [employee]: prev[employee].map((row) =>
//         row.rowId === rowId ? { ...row, [field]: value } : row
//       ),
//     }));
//   };
//   const employeeTables = {
//     Munner: [{ rowId: 1, cash100: "", cash200: "", cash500: "" }],
//     Kalyan: [{ rowId: 1, cash100: "", cash200: "", cash500: "" }],
//     Srihari: [{ rowId: 1, cash100: "", cash200: "", cash500: "" }],
//   };

  return (
    <>
      <div style={{}}>
        <br />
        <Typography variant="h5" align="center" style={{ fontWeight: "bold" }}>
          ATM DATA UPLOAD FORM
        </Typography>
        <Grid
          container
          style={{
            paddingTop: "2rem",
            display: "flex",
            gap: "3rem",
            margin: "1rem",
          }}
        >
          {/* ///////////////////UPLOAD Form/////////////// */}
          <Grid item lg={7}>
            <form onSubmit={handleSubmit}>
              <TableContainer
                // id="capture-section"
                component={Paper}
                sx={{
                  margin: "dense",
                  size: "small",
                  maxHeight: "590px",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                <Table
                  margin="dense"
                  size="small"
                  aria-label="sticky table"
                  stickyHeader
                  id="capture-section"
                >
                  <TableHead margin="dense" size="small">
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>S.No</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        <TextField
                          label="ATM ID"
                          value={filters.atmId}
                          onChange={(e) => handleFilterChange(e, "atmId")}
                          variant="standard"
                          size="small"
                        />
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        <TextField
                          label="ATM Name"
                          value={filters.atmName}
                          onChange={(e) => handleFilterChange(e, "atmName")}
                          variant="standard"
                          size="small"
                        />
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        <TextField
                          label="100 Cash"
                          value={filters.cash100}
                          onChange={(e) => handleFilterChange(e, "cash100")}
                          variant="standard"
                          size="small"
                        />
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        <TextField
                          label="200 Cash"
                          value={filters.cash200}
                          onChange={(e) => handleFilterChange(e, "cash200")}
                          variant="standard"
                          size="small"
                        />
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        <TextField
                          label="500 Cash"
                          value={filters.cash500}
                          onChange={(e) => handleFilterChange(e, "cash500")}
                          variant="standard"
                          size="small"
                        />
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Total
                      </TableCell>
                      {/* <TableCell style={{ fontWeight: "bold" }}> */}
                      <FormControl fullWidth>
                        <Select
                          value={row.employee}
                          onChange={(e) => handleFilterChange(e, "employee")}
                          displayEmpty
                        >
                          <MenuItem value="">All</MenuItem>
                          <MenuItem value="Kalyan">Kalyan</MenuItem>
                          <MenuItem value="Munner">Munner</MenuItem>
                          <MenuItem value="Srihari">Srihari</MenuItem>
                          {employeeNames.map((name, index) => (
                            <MenuItem key={index} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {/* </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.atmId}</TableCell>
                        <TableCell>{row.atmName}</TableCell>
                        <TableCell>
                          {editRowId === row.id ? (
                            <TextField
                              value={row.cash100}
                              onChange={(event) =>
                                handleInputChange(event, row.id, "cash100")
                              }
                              variant="standard"
                            />
                          ) : (
                            row.cash100
                          )}
                        </TableCell>
                        <TableCell>
                          {editRowId === row.id ? (
                            <TextField
                              value={row.cash200}
                              onChange={(event) =>
                                handleInputChange(event, row.id, "cash200")
                              }
                              variant="standard"
                            />
                          ) : (
                            row.cash200
                          )}
                        </TableCell>
                        <TableCell>
                          {editRowId === row.id ? (
                            <TextField
                              value={row.cash500}
                              onChange={(event) =>
                                handleInputChange(event, row.id, "cash500")
                              }
                              variant="standard"
                            />
                          ) : (
                            row.cash500
                          )}
                        </TableCell>
                        <TableCell>{row.total}</TableCell>
                        <TableCell>
                          <FormControl fullWidth>
                            <Select
                              value={row.employee}
                              onChange={(e) =>
                                editRowId === row.id
                                  ? handleEmployeeChange(e, row.id)
                                  : setSelectedEmployee(e.target.value)
                              }
                              disabled={editRowId !== row.id}
                            >
                              <MenuItem value="Kalyan">Kalyan</MenuItem>
                              <MenuItem value="Munner">Munner</MenuItem>
                              <MenuItem value="Srihari">Srihari</MenuItem>
                              {employeeNames.map((name, index) => (
                                <MenuItem key={index} value={name}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>

                        <TableCell>
                          {editRowId === row.id ? (
                            <Button onClick={handleSave}>Save</Button>
                          ) : (
                            <>
                              <Button onClick={() => handleEdit(row.id)}>
                                Edit
                              </Button>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        style={{ fontWeight: "bold", fontSize: "18px" }}
                      >
                        <strong>Totals:</strong>{" "}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        {row.cash100}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        {row.cash200}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        {row.cash500}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        {row.total}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

              <div align="center">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ marginTop: "1rem" }}
                >
                  Submit
                </Button>
                <Button
                  download
                  variant="contained"
                  color="primary"
                  onClick={handleDownloadScreenshot}
                  style={{ marginLeft: "1rem", marginTop: "1rem" }}
                >
                  Download
                </Button>
              </div>
            </form>
          </Grid>

          {/* ///////////////////////////Total Cash//////////////// */}
          <Grid item lg={3} style={{ width: "25%" }}>
            <div style={{ width: "100%", marginRight: "2rem" }}>
              <TableContainer component={Paper} margin="dense" size="small">
                <Table size="small">
                  <TableHead margin="dense" size="small">
                    <TableRow
                      style={{
                        textAlign: "center",
                        backgroundColor: "#007BFF",
                      }}
                    >
                      <TableCell>
                        <b style={{ color: "white" }}>100 Cash</b>
                      </TableCell>
                      <TableCell>
                        <b style={{ color: "white" }}>200 Cash</b>
                      </TableCell>
                      <TableCell>
                        <b style={{ color: "white" }}>500 Cash</b>
                      </TableCell>
                      <TableCell>
                        <b style={{ color: "white" }}>Total</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* First Row */}
                    <TableRow>
                      <TableCell>
                        <input
                          style={{
                            width: "80px",
                            height: "20px",
                          }}
                          value={secondRow.total100}
                          onChange={(e) => handleChange1(e, "total100")}
                          type="number"
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          style={{
                            width: "80px",
                            height: "20px",
                          }}
                          value={secondRow.total200}
                          onChange={(e) => handleChange1(e, "total200")}
                          type="number"
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          style={{
                            width: "80px",
                            height: "20px",
                          }}
                          value={secondRow.total500}
                          onChange={(e) => handleChange1(e, "total500")}
                          type="number"
                        />
                      </TableCell>
                      <TableCell>
                        {parseInt(secondRow.total100 || "0") +
                          parseInt(secondRow.total200 || "0") +
                          parseInt(secondRow.total500 || "0")}
                      </TableCell>
                    </TableRow>
                    {/* Second Row */}
                    <TableRow>
                      <TableCell>{totals.total100}</TableCell>
                      <TableCell>{totals.total200}</TableCell>
                      <TableCell>{totals.total500}</TableCell>
                      <TableCell>
                        {totals.total100 + totals.total200 + totals.total500}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{getStyledSymbol(isMatching100)}</TableCell>
                      <TableCell>{getStyledSymbol(isMatching200)}</TableCell>
                      <TableCell>{getStyledSymbol(isMatching500)}</TableCell>
                      <TableCell>{getStyledSymbol(isMatchingTotal)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <br/>
            <div>
              {/* <Box
              sx={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
                gap: "20px",
              }}
            >
              {Object.keys(tables).map((employee) => (
                <Box
                  key={employee}
                  sx={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#f9f9f9",
                    marginBottom: "2rem",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{
                      textAlign: "center",
                      marginBottom: "10px",
                      color: "#007BFF",
                    }}
                  >
                    Employee: {employee}
                  </Typography>
                  <Table
                    sx={{
                      width: "100%",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                    }}
                    size="small"
                  >
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#007BFF" }}>
                        <TableCell
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Cash 100's
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Cash 200's
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Cash 500's
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tables[employee]?.map((row) => (
                        <TableRow
                          key={row.rowId}
                          sx={{
                            "&:nth-of-type(even)": {
                              backgroundColor: "#e3f2fd",
                            },
                          }}
                        >
                          {["cash100", "cash200", "cash500"].map((cashType) => (
                            <TableCell
                              key={cashType}
                              sx={{ textAlign: "center" }}
                            >
                              <input
                                style={{
                                  width: "80px",
                                  height: "25px",
                                  padding: "5px",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                }}
                                value={row[cashType]}
                                onChange={(e) =>
                                  handleInputChange2(
                                    employee,
                                    row.rowId,
                                    cashType,
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              ))}
            </Box> */}
            </div>
          </Grid>
        </Grid>
      </div>
      <br />
    </>
  );
};

export default EntryData;
